<template>
  <div>
    <div style="margin-left: 20px;margin-right: 20px;font-size: 15px;">
      <div v-for="item in data.item" :key="item.exam_item_id" :id="item.exam_item_id" class="page-content">
        <div style="width: 100%;height: 24px;">
          <div style="display: block;float: left;" :caclu="num=0"><b>{{item.name}}(总分：{{item.questions.score}})(打印日期：{{date}})</b></div>
          <vue-qr style="display: block;float: right;margin-top: -10px;margin-right: -10px;" :size="100" :text="href + item.exam_item_id"></vue-qr>
        </div>
        <div v-if="item.questions.questions.add.length > 0">
          <p><b>改错练习</b></p>
          <div v-for="question in item.questions.questions.add" :key="question.question_id">
            <div :caclu="num++" :caclu1="smallNum=0"></div>
            <div v-html="question.title"></div>
            <div style="margin-top: 1px;" v-for="questionItem in question.items" :key="questionItem.item_id">
              <span :caclu="smallNum++" style="border:3px solid;border-color: #A8FF24;width: 60px;display: inline-block;">
                <span>{{num}}</span><span v-if="question.items.length > 1">.{{smallNum}}</span>:
              </span>&nbsp;
              <span>{{checkOption(question.question_from, status.questionFrom)}}{{question.question_to}}</span>
              <span>({{questionItem.score}}分)</span>&nbsp;
              <span v-html="questionItem.item_content"></span>
              <span v-if="questionItem.need_space === 1"><br/><br/><br/><br/><br/><br/></span>
            </div>
          </div>
          <div style="page-break-after: always;"></div>
        </div>
        <div v-for="(questionItem, index) in item.questions.questions.questionType" :key="index">
          <p><b>{{checkOption(index, status.questionType)}}</b></p>
          <div v-for="question in questionItem" :key="question.question_id">
            <div :caclu="num++" :caclu1="smallNum=0"></div>
            <span v-if="question.items.length > 1">{{num}}</span><div v-html="question.title"></div>
            <div style="margin-top: 1px;" v-for="questionItem in question.items" :key="questionItem.item_id">
              <span :caclu="smallNum++" style="border:3px solid;border-color: #A8FF24;width: 60px;display: inline-block;">
                <span>{{num}}</span><span v-if="question.items.length > 1">.{{smallNum}}</span>:
              </span>&nbsp;
              <span>{{checkOption(question.question_from, status.questionFrom)}}{{question.question_to}}</span>
              <span>({{questionItem.score}}分)</span>&nbsp;
              <span v-html="questionItem.item_content"></span>
              <span v-if="questionItem.need_space === 1"><br/><br/><br/><br/><br/><br/></span>
            </div>
          </div>
        </div>
        <div style="page-break-after: always;"></div>
        <div style="opacity: 0">草稿</div>
        <div style="page-break-after: always;"></div>
      </div>
    </div>
  </div>
</template>
<script>
import { status } from '@/plugins/permission'
import { mapState } from 'vuex'
import { mixin, propValue } from '@/mixins/index'
import VueQr from 'vue-qr'
export default {
  mixins: [mixin(), propValue()],
  components: {
    VueQr
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  data () {
    return {
      reg: /<p>/gi,
      href: 'https://wechat.widenteen.com/examination?id=',
      data: [],
      status: {
        questionFrom: [],
        questionType: []
      },
      date: new Date().toLocaleDateString()
    }
  },
  methods: {
    checkOption (value, option) {
      for (let i = 0; i < option.length; i++) {
        if (Number(option[i].value) === Number(value)) {
          return option[i].label
        }
      }
    }
  },
  async created () {
    this.status.questionFrom = status.questionFrom
    this.status.questionType = await this.$store.dispatch('getOption', 'question_type')
    this.data = await this.getFetch('/api/examination/paper', { id: this.$route.query.id })
    const studentList = this.data.item
    for (let i = 0; i < studentList.length; i++) {
      const questions = studentList[i].questions.questions
      const add = []
      const questionType = {}
      for (let item in questions) {
        // 系统出题单独列出
        if (questions[item].is_add) {
          add.push(questions[item])
          continue
        }
        // 其它题目进行分类
        if (!questionType[questions[item].question_type]) {
          questionType[questions[item].question_type] = []
        }
        questionType[questions[item].question_type].push(questions[item])
      }
      studentList[i].questions.questions = {
        add,
        questionType
      }
    }
  }
}
</script>

<style lang="css">
  .kfformula {
    display: inline-block;
    vertical-align: middle;
    margin-left: -20px;
    margin-right: -20px;
  }
  @page {
    margin-left: 50px;
    margin-right: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    size: auto;
  }
  .page-content table
  {
      border-collapse: collapse;
      text-align: center;
      vertical-align: middle;
  }
  .page-content table,th, td
  {
      border: 1px solid black;
  }
</style>
